import NumberFormat from 'react-number-format'
import RequiredAsterisk from '@/src/common/components/elements/Forms/RequiredAsterisk'
import { useState } from 'react'
import {
    FieldWrapper,
    StyledLabel,
    TextInput,
} from '@/src/common/components/elements/Forms/StyledComponents'

function determineLabelClassName(
    inputStyle: 'stripe',
    isFocused: boolean,
    value: any
) {
    let className = ''

    // for stripe fields, if focused or has value, add focused
    if (inputStyle === 'stripe' && (isFocused || !!value)) {
        className += 'focused'
    }

    if (inputStyle !== 'stripe' && isFocused) {
        className += 'focused'
    }

    return className
}

export const TextInputWithLabel = (props: any) => {
    const {
        initialError,
        initialTouched,
        initialValue,
        touched,
        numFormat,
        value,
        label,
        placeholder,
        hasError,
        formErrors,
        isRequired,
        inputStyle,
        ...theRest
    } = props

    const [isFocused, setFocused] = useState(false)
    const placeHolderValue = placeholder ?? label

    return (
        <FieldWrapper>
            <StyledLabel
                role="textbox"
                aria-label={`${label} input field`}
                htmlFor={props.name}
                css={{ marginRight: 15 }}
                hidden={!value && !placeholder}
                labelStyle={inputStyle}
                className={determineLabelClassName(
                    inputStyle,
                    isFocused,
                    value
                )}
            >
                {label}
                {props?.isRequired && <RequiredAsterisk />}
            </StyledLabel>
            {numFormat ? (
                <NumberFormat
                    format={numFormat}
                    customInput={TextInput}
                    value={value}
                    placeholder={
                        inputStyle !== 'stripe' || isFocused
                            ? placeHolderValue
                            : ''
                    }
                    className={`${hasError ? 'error' : null} ${
                        isFocused ? 'focused' : null
                    }`}
                    {...theRest}
                    onBlur={(e: any) => {
                        if (!e.currentTarget.contains(e.relatedTarget)) {
                            setFocused(false)
                        }
                    }}
                    onFocus={() => {
                        setFocused(!isFocused)
                    }}
                    inputStyle={inputStyle}
                />
            ) : (
                <TextInput
                    value={value}
                    placeholder={placeholder ?? label}
                    className={hasError ? 'error' : null}
                    onBlur={(e: any) => {
                        if (!e.currentTarget.contains(e.relatedTarget)) {
                            setFocused(false)
                        }
                    }}
                    onFocus={() => {
                        setFocused(!isFocused)
                    }}
                    inputStyle={inputStyle}
                    {...theRest}
                />
            )}
        </FieldWrapper>
    )
}
