import { styled } from '@/src/stitches.config'
import { fadeInFast } from '@/src/common/animations'
import * as LabelPrimitive from '@radix-ui/react-label'
import { fadeIn } from '@cloudinary/url-gen/actions/effect'

export const FieldWrapper = styled('div', {
    position: 'relative',
})

export const FieldWrapperWithGap = styled('div', {
    display: 'flex',
    fd: 'column',
    gap: 15,
})

export const TextInput = styled('input', {
    zIndex: 0,
    all: 'unset',
    display: 'block',
    border: '2px solid $darkest',
    borderRadius: '$2',
    backgroundColor: '$white',
    padding: '$5 $2',
    height: 35,
    fontSize: 16,
    lineHeight: 1,
    width: '100%',
    maxWidth: '100%',
    boxSizing: 'border-box',
    color: '$darkest',
    '&:focus': {
        borderColor: '$primary',
    },
    '&.error': {
        border: '2px solid $error',
    },
    variants: {
        inputStyle: {
            stripe: {
                backgroundColor: '$white',
                border: '1px solid #e6e6e6',
                borderRadius: 4,
                transition:
                    'background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease',
                boxShadow:
                    '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)',
                fontSize: 16,
                boxSizing: 'border-box',
                padding: '32px .75rem .75rem .75rem',
                height: 63.536,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                '&:focus': {
                    animation: `${fadeInFast} 1.5s ease-in-out forwards`,
                    boxShadow:
                        '0 0 0 3px hsla(210, 96%, 45%, 25%), 0 1px 1px 0 rgba(0, 0, 0, 0.08)',
                    borderColor: '#6772e5',
                    outline: 'none',
                    transition:
                        'background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease',
                },
                '&.error': {
                    color: '#D64045',
                },
            },
        },
    },
})

export const StyledLabel = styled(LabelPrimitive.Root, {
    zIndex: 1,
    padding: '0 0.3em',
    fontSize: '0.75em',
    top: '-0.6em',
    left: '0.9em',
    userSelect: 'none',
    position: 'absolute',
    backgroundColor: '$white',
    color: '$dark',
    animation: `${fadeIn} 200ms`,
    variants: {
        hidden: {
            true: {
                display: 'none',
            },
        },
        labelStyle: {
            stripe: {
                pointerEvents: 'none',
                position: 'absolute',
                top: '50%',
                left: '1.5%',
                transform: 'translateY(-50%)',
                fontSize: 16,
                backgroundColor: 'transparent',
                color: '#30313d !important',
                willChange: 'transform',
                '&.focused': {
                    transform:
                        'translateY(-22px) translateX(-5px) scale(0.8888888888888888) !important',
                    paddingBottom: 'max(var(2px), var(.25rem))',
                    transition:
                        'transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1)',
                    opacity: 0.8,
                    '@media (max-width: 420px)': {
                        transform:
                            'translateY(-22px) translateX(-3px) scale(0.8888888888888888) !important',
                    },
                },
            },
        },
    },
})
