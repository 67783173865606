import { ValidationError } from 'yup'

export function clearFormErrorForField({
    path,
    formErrors,
    setFormErrors,
}: {
    path: string
    formErrors: ValidationError[]
    setFormErrors: Function
}) {
    if (!formErrors || !formErrors.length) {
        setFormErrors(null)
        return
    }
    setFormErrors(formErrors?.filter((x) => x.path !== path))
}
