import {
    AlertText,
    AlertTextWrapper,
    AlertWrapper,
} from '@/src/common/components/elements/Alerts/StyledComponents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle'
import { faSquareXmark } from '@fortawesome/free-solid-svg-icons/faSquareXmark'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { ReactNode } from 'react'

// add text elements as children
const AlertWithIcon = ({
    iconName,
    children,
    size = 'md',
    style = {},
}: {
    iconName: 'error' | 'warning' | 'success' | 'info'
    size?: 'sm' | 'md'
    children?: ReactNode
    style?: object
}) => {
    let icon

    switch (iconName) {
        case 'success':
            icon = faCheckSquare
            break
        case 'error':
            icon = faSquareXmark
            break
        case 'warning':
            icon = faExclamationTriangle
            break
        case 'info':
            icon = faCircleInfo
            break
        default:
            icon = faExclamationTriangle
            break
    }

    return (
        <AlertWrapper variant={iconName} style={style} size={size}>
            <FontAwesomeIcon icon={icon} />
            <AlertTextWrapper>
                <AlertText>{children}</AlertText>
            </AlertTextWrapper>
        </AlertWrapper>
    )
}

export default AlertWithIcon
