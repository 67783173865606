import { styled } from '@/src/stitches.config'

export const FormErrorMessage = styled('div', {
    all: 'unset',
    m: 0,
    padding: '5px 0px 2px 5px',
    height: 'auto',
    fontSize: '15px',
    color: '$error',
    display: 'block',
    lineHeight: '20px',
    variants: {
        variant: {
            'error-message': {
                mt: -15,
            },
        },
    },
})
